import type {
	IEntityOmsOrder,
	IEntityOrder,
	IEntityOrderComputedValues,
	IEntityOrderItem,
	IEntityOrderItemBase,
	IEntityOrderItemComputedValues,
	IOrderPayment,
} from 'module/orders';
import {
	extractBillablePartyEntityFromOrder,
	extractCustomerEntityFromOrder,
	extractDistributionPartnerEntityFromOrder,
	extractPartnerEntityFromOrder,
} from 'module/orders/utils/entityExtractor';
import {
	hasQuotedCustomPrices,
	isEndCustomerOrder,
	isOrderItemBusiness,
	isOrderType,
	isOrderUnfinished,
} from 'module/orders/utils/common';
import { getOrderItemQuantity, getOrderItemUnit } from 'module/orders/utils/orderItemUtils';
import { LineItemStatusEnum, OrderTypeEnum } from 'module/orders/enums';

export const orderEntityNormalizer = {
	normalizeList(items: IEntityOmsOrder[]): IEntityOrder[] {
		return items.map((item) => this.normalize(item));
	},

	normalize(order: IEntityOmsOrder): IEntityOrder {
		const isEndCustomer = isEndCustomerOrder(order);
		const lineItems: IEntityOrderItem[] =
			order.lineItems
				?.filter((item) => item.status !== LineItemStatusEnum.INVALID)
				.map((item) => this.normalizeItem(item, isEndCustomer)) || [];

		return {
			...order,
			payment: this.normalizePayment(order),
			lineItems,
			// extracting
			billableParty: extractBillablePartyEntityFromOrder(order),
			partner: extractPartnerEntityFromOrder(order),
			distributionPartner: extractDistributionPartnerEntityFromOrder(order),
			customer: extractCustomerEntityFromOrder(order),
			currency: order.currency || order.totalAmountWithoutTaxCurrencyCode,
			computedValues: this.normalizeComputedValues(order, lineItems),
			isPayAsYouGoOrder: isOrderType(order, OrderTypeEnum.PAYG),
		};
	},

	normalizeItem(item: IEntityOrderItemBase, isEndCustomerOrder: boolean): IEntityOrderItem {
		const { price, pricing } = item;
		let computedPrice: IEntityOrderItemComputedValues['price'];
		const quantity = getOrderItemQuantity(item);
		const unit = getOrderItemUnit(item);
		const isBusiness = isOrderItemBusiness(item);

		// Restore pricing data from saved pricing response
		if (pricing) {
			computedPrice = {
				customer: {
					prefixType: 'customer',
					unit: isEndCustomerOrder ? pricing.unitPrice : pricing.customerPrice?.unit,
					total: isEndCustomerOrder ? pricing.linePriceWOTax : pricing.customerPrice?.total,
				},
				partner: {
					unit: pricing.unitPrice,
					total: pricing.linePriceWOTax,
				},
			};
		}
		// Fallback for old orders
		else {
			computedPrice = {
				customer: {
					prefixType: isEndCustomerOrder ? 'customer' : 'srp',
					unit: isEndCustomerOrder ? price?.discountedUnit : price?.saleUnit,
					total: isEndCustomerOrder ? price?.withoutTax : price?.totalSrp,
				},
				partner: {
					unit: price?.discountedUnit,
					total: price?.withoutTax,
				},
			};
		}

		// Customer Quoted Price
		if (price?.savedCustomer) {
			computedPrice.quotedCustomer = {
				unit: price.savedCustomer,
				total: price.savedCustomer * (isBusiness ? unit : quantity),
			};
		}

		return {
			...item,
			computedValues: {
				quantity,
				unit,
				price: computedPrice,
			},
		};
	},

	normalizePayment(order: IEntityOmsOrder): IOrderPayment {
		const { payment } = order;
		const type = isOrderUnfinished(order) && Boolean(payment) ? undefined : payment?.type;

		return {
			...payment,
			type,
		};
	},

	normalizeComputedValues(order: IEntityOmsOrder, lineItems: IEntityOrderItem[]): IEntityOrderComputedValues {
		const isEndCustomer = isEndCustomerOrder(order);

		let price: IEntityOrderComputedValues['price'];
		if (order.pricing) {
			price = {
				customer: {
					prefixType: 'customer',
					total: isEndCustomer ? order.pricing.totalAmountWithoutTax : order.pricing.customerTotalPrice,
				},
				partner: {
					total: order.pricing.totalAmountWithoutTax,
				},
			};
		} else {
			price = {
				customer: {
					prefixType: isEndCustomer ? 'customer' : 'srp',
					total: isEndCustomer ? order.totalAmountWithoutTax : this.calculateTotalSrpPrice(order),
				},
				partner: {
					total: order.totalAmountWithoutTax,
				},
			};
		}

		if (hasQuotedCustomPrices(lineItems)) {
			price.quotedCustomer = {
				total: lineItems.reduce((acc, item) => acc + (item.computedValues.price.quotedCustomer?.total || 0), 0),
			};
		}

		return {
			isEndCustomerOrder: isEndCustomer,
			price,
		};
	},

	calculateTotalSrpPrice(order: IEntityOmsOrder): number {
		return (
			order.lineItems?.reduce((total, item) => {
				const qty = isOrderItemBusiness(item) ? getOrderItemUnit(item) : getOrderItemQuantity(item);
				const { price = {} } = item;
				const { saleUnit: unitPrice = 0 } = price;

				return total + unitPrice * qty;
			}, 0) || 0
		);
	},
};
