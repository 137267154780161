import type { FunctionComponent } from 'react';
import { accountConfig } from 'module/account/accountConfig';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { Nav } from 'react-bootstrap';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import { useIsPayAsYouGoEligible } from 'module/account/hooks/useIsPayAsYouGoEligible';
import { AccountRoutes } from 'module/account/AccountRoutes';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { useAuthContext } from 'js/contexts';
import { useFeatureFlag } from 'js/hooks/useFeatureFlag';
import { FeatureFlagEnum } from 'config/featureFlags';

export const AccountContainer: FunctionComponent = () => {
	const { trNamespace } = accountConfig;
	const { t } = useTranslation(trNamespace);
	const { authCompany } = useAuthContext();
	const isPayAsYouGoEligible = useIsPayAsYouGoEligible();
	const taxExemptionSectionFlag = useFeatureFlag(FeatureFlagEnum.TAX_EXEMPTION_SECTION);

	return (
		<>
			<Nav variant="tabs" as="ul">
				<Nav.Item as="li">
					<Nav.Link as={NavLink} end to={RouteName.ACCOUNT.DEFAULT}>
						{t('page.default.title')}
					</Nav.Link>
				</Nav.Item>
				{authCompany?.isTaxExemptEligible && taxExemptionSectionFlag.isEnabled && (
					<Nav.Item as="li">
						<Nav.Link as={NavLink} end to={RouteName.ACCOUNT.TAX_EXEMPTION}>
							{t('page.taxExemption.title')}
						</Nav.Link>
					</Nav.Item>
				)}
				{isPayAsYouGoEligible && (
					<Nav.Item as="li">
						<Nav.Link as={NavLink} to={RouteName.ACCOUNT.FLEXIBLE_BILLING}>
							{t('page.flexibleBilling.title')}
						</Nav.Link>
					</Nav.Item>
				)}
			</Nav>
			<DefaultContainer>
				<ModuleContainer moduleMenuConfig={accountConfig} />

				<AccountRoutes />
			</DefaultContainer>
		</>
	);
};
