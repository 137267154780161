import { ReactComponent as AvastLogo } from 'assets/image/avast/logo.svg';
import { ReactComponent as AvastLogoOnly } from 'assets/image/avast/logo-only.svg';
import { ReactComponent as EmpowerLogo } from 'assets/image/empower/logo.svg';
import { ReactComponent as EmpowerLogoOnly } from 'assets/image/empower/logo-only.svg';
import { CONFIG } from 'config';
import { ThemeEnum } from 'js/enums';
import type { FunctionComponent, SVGProps } from 'react';

interface IAppLogoProps {
	collapsedLogo?: boolean;
	title?: string;
	className?: string;
}

export const AppLogo = (props: IAppLogoProps) => {
	const { collapsedLogo, ...rest } = props;
	let LogoComponent: FunctionComponent<SVGProps<SVGSVGElement>>;

	switch (CONFIG.THEME) {
		case ThemeEnum.EMPOWER:
			LogoComponent = collapsedLogo ? EmpowerLogoOnly : EmpowerLogo;
			break;
		default:
			LogoComponent = collapsedLogo ? AvastLogoOnly : AvastLogo;
			break;
	}

	return <LogoComponent {...rest} />;
};
