import { Alert, H1, H2 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import type { IRegistrationDataForm } from 'module/registration';
import { FormikForm } from 'js/components/formik/FormikForm';
import { Formik } from 'formik';
import { RegistrationBaseInfoCard } from 'module/registration/components/RegistrationBaseInfoCard';
import { useRegistrationFormValidator } from 'module/registration/utils/validator';
import { useApiPostRegistrationForm } from 'module/registration/hooks/useApiRegistration';
import { sendRegistrationInfoApiError } from 'module/registration/utils/apiError';
import { SubmitButton } from 'js/components/atoms/Button';
import { PartnerRegistrationForm } from 'module/registration/forms/PartnerRegistrationForm';
import { useFinishRegistration } from 'module/registration/hooks/useFinishRegistration';
import { RegistrationStepEnum } from 'module/registration/enums';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';

export const RegistrationInfo = () => {
	const { t } = useTranslation(registrationConfig.trNamespace);
	const { setStep, partner, partnerId, isTaxExemptEligible } = useRegistrationContext();
	const { loadingModalRef } = useAppContext();
	const { validate } = useRegistrationFormValidator();
	const { setError } = useApiErrorContext();
	const { setAuthPartner } = useAuthContext();
	const finishRegistration = useFinishRegistration();
	const { mutateAsync: sendRegistrationData } = useApiPostRegistrationForm({ config: { params: { partnerId } } });

	return (
		<>
			<Formik<IRegistrationDataForm>
				initialValues={partner}
				validate={validate}
				onSubmit={async (values, { setSubmitting }) => {
					loadingModalRef.current?.show({ title: t('common.submitting') });

					const partnerResponse = await sendRegistrationData(values).catch((error) => {
						setError({ error, resolve: sendRegistrationInfoApiError });
						loadingModalRef.current?.hide();
					});

					setSubmitting(false);
					loadingModalRef.current?.hide();

					if (!partnerResponse) {
						return;
					}

					setAuthPartner(partnerResponse.data);

					// Redirect to Tax Exemption Page
					if (isTaxExemptEligible) {
						setStep(RegistrationStepEnum.TAX_EXEMPTION);
						return;
					}

					// Else it is final step -> finish registration
					await finishRegistration();
				}}
			>
				<FormikForm testId="registrationInfoForm">
					<H1 className="text-center" bold>
						{t('steps.registrationInfo')}
					</H1>

					<div className="mt-4">
						<Alert variant="info">{t('alert.changeAccountDataInPartnerPortal')}</Alert>
						<RegistrationBaseInfoCard />
					</div>

					<div className="mt-8">
						<H2 className="mb-3 text-center" bold>
							{t('page.registrationInfo.accountInformation')}
						</H2>
						<Alert variant="info">{t('alert.checkCurrentAccountInfo')}</Alert>
						<PartnerRegistrationForm />
					</div>
					<hr className="my-4" />
					<p className="text-center">
						<SubmitButton>{t('common:actions.confirm')}</SubmitButton>
					</p>
				</FormikForm>
			</Formik>
		</>
	);
};
