import { Button, ButtonComposition, H1, H2 } from '@avast/react-ui-components';
import { FormikForm } from 'js/components/formik/FormikForm';
import { SubmitButton } from 'js/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { Formik } from 'formik';
import { useApiRegistrationUploadTaxExemption } from 'module/registration/hooks/useApiRegistration';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { sendTaxExemptionFormApiError } from 'module/registration/utils/apiError';
import { AsyncSuccessModal, type TAsyncSuccessModalProps } from 'js/components/molecules/Modal/AsyncSuccessModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { TaxExemptionForm } from 'module/account/forms/TaxExemptionForm';
import { useFinishRegistration } from 'module/registration/hooks/useFinishRegistration';
import { useTaxExemptionFormValidator } from 'module/account/utils/validator';
import type { ITaxExemptionFormFields } from 'module/account';
import { taxExemptionFormNormalizer } from 'module/account/normalizer';
import { Announcements } from 'submodule/Announcements';
import { useApiErrorContext, useAppContext } from 'js/contexts';
import { useFeatureFlag } from 'js/hooks/useFeatureFlag';
import { FeatureFlagEnum } from 'config/featureFlags';

export const TaxExemption = () => {
	const { t } = useTranslation(registrationConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { partnerId, partner, step } = useRegistrationContext();
	const { loadingModalRef } = useAppContext();
	const finishModalRef = useAsyncModalRef<TAsyncSuccessModalProps>();
	const { validate } = useTaxExemptionFormValidator();
	const { mutateAsync: uploadTaxExemptionForm } = useApiRegistrationUploadTaxExemption({
		config: { params: { partnerId } },
	});
	const finishRegistration = useFinishRegistration();
	const taxExemptionFlag = useFeatureFlag(FeatureFlagEnum.TAX_EXEMPTION_FORM);

	if (taxExemptionFlag.isDisabled) {
		return (
			<div>
				<H1 className="text-center mb-4" bold>
					{t(`steps.${step}`)}
				</H1>
				<Announcements placement="TAX_EXEMPTION_REGISTRATION" />

				<ButtonComposition className="justify-content-center mt-8">
					<Button onClick={finishRegistration} variant="primary" testId="continue">
						{t('moduleSecurity:form.submit')}
					</Button>
				</ButtonComposition>

				<AsyncSuccessModal forwardedRef={finishModalRef} />
			</div>
		);
	}

	return (
		<Formik<ITaxExemptionFormFields>
			initialValues={{
				companyName: partner.companyName || '',
				taxAuthority: '',
				startDate: null,
				endDate: null,
				file: null,
			}}
			onSubmit={async (values, { setSubmitting }) => {
				loadingModalRef.current?.show({ title: t('common.submitting') });

				const formData = taxExemptionFormNormalizer.denormalize(values);
				const formResponse = await uploadTaxExemptionForm(formData).catch((error) => {
					setError({
						error,
						resolve: sendTaxExemptionFormApiError,
						onClose() {
							loadingModalRef.current?.hide();
							setSubmitting(false);
						},
					});
				});

				if (!formResponse) {
					return;
				}

				await finishRegistration();
			}}
			validate={validate}
		>
			{({ isSubmitting }) => (
				<FormikForm encType="multipart/form-data" testId="taxExemptionForm">
					<H1 type="h2">{t('page.taxExemption.title')}</H1>
					<H2 type="h3">{t('page.taxExemption.subtitle')}</H2>
					<hr />
					<Announcements placement="TAX_EXEMPTION_REGISTRATION" />
					<div className="mb-4">
						<TaxExemptionForm countryCode={partner.countryCode} />
					</div>

					<hr />
					<ButtonComposition className="justify-content-between">
						<Button loading={isSubmitting} onClick={finishRegistration} variant="outline-primary" testId="skip">
							{t('common.skip')}
						</Button>
						<SubmitButton>{t('common:actions.submitForm')}</SubmitButton>
					</ButtonComposition>

					<AsyncSuccessModal forwardedRef={finishModalRef} />
				</FormikForm>
			)}
		</Formik>
	);
};
