import { type FeatureFlagEnum, getFeatureFlag } from 'config/featureFlags';
import { useAuthContext } from 'js/contexts';

interface IUseFeatureFlag {
	isEnabled: boolean;
	isDisabled: boolean;
}

export const useFeatureFlag = (flag: FeatureFlagEnum): IUseFeatureFlag => {
	const authContext = useAuthContext();
	const isEnabled = getFeatureFlag(flag).isEnabled({ authContext });

	return {
		isEnabled,
		isDisabled: !isEnabled,
	};
};
