import { type FunctionComponent, memo } from 'react';
import { DynamicBreadcrumbs } from 'js/components/molecules/DynamicBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import { isEmptySelector, totalItemsSelector } from 'js/selectors/order';
import { ButtonTextBadge, LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { AuthPartnerDropdownCol, HeaderLogo } from './components';
import { IconCart } from 'assets/image/icon';
import { isEnvProduction } from 'js/utils/app';
import { EnvLabel } from 'js/components/common';
import { useAppContext, useAuthContext, useOrderContext } from 'js/contexts';

const _AppHeader: FunctionComponent = () => {
	const { isAuthorizedBuyer } = useAuthContext();
	const { orderState } = useOrderContext();
	const { sidebarCollapsed } = useAppContext();
	const { t } = useTranslation();
	const isEmptyCart = isEmptySelector(orderState);

	return (
		<div className="header-app">
			<HeaderLogo collapsedLogo={sidebarCollapsed.value} />

			<Col className="header-content ps-lg-4 pe-0 h-100">
				<DynamicBreadcrumbs />
				<Row align="center" size="sm" className="h-100 mx-0">
					{!isEnvProduction && (
						<Col xs="auto">
							<EnvLabel />
						</Col>
					)}
					{isAuthorizedBuyer && (
						<Col xs="auto">
							<LinkButton
								size="sm"
								variant={isEmptyCart ? 'white' : 'tertiary'}
								iconLeft={<IconCart style={{ height: '22px' }} />}
								to={isEmptyCart ? RouteName.PURCHASE.DEFAULT : RouteName.PURCHASE.ORDER}
								testId="cart"
							>
								<ButtonTextBadge count={totalItemsSelector(orderState)}>{t('common:header.cart')}</ButtonTextBadge>
							</LinkButton>
						</Col>
					)}
					<AuthPartnerDropdownCol />
				</Row>
			</Col>
		</div>
	);
};

export const AppHeader = memo(_AppHeader);
