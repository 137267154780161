import type { IPaymentTypeError } from 'module/purchase/index';
import { Tooltip } from '@avast/react-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';

interface IPaymentTypeErrorProps {
	error?: IPaymentTypeError;
	className?: string;
	isLoading?: boolean;
}

export const PaymentTypeError = (props: IPaymentTypeErrorProps) => {
	const { error, className, isLoading } = props;

	if (!error) {
		return null;
	}

	if (isLoading) {
		return <Skeleton className={className} width="100%" />;
	}

	return (
		<div className={className}>
			<Tooltip placement="top" content={error.detail}>
				<div className="tooltip-text text-info">
					<FontAwesomeIcon icon={faInfoCircle} />
					<span>{error.text}</span>
				</div>
			</Tooltip>
		</div>
	);
};
