import { type ReactElement, useRef } from 'react';
import { Modal } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import type { IEntityOrder } from 'module/orders';
import { AvastCheckout } from 'module/purchase/components/submitOrder/AvastCheckout';
import { STATUS_SUCCESS } from 'appConstants';
import { logDebug } from 'js/utils/app';
import { useAppContext } from 'js/contexts';
import { useApiCancelSubmission } from 'module/purchase/hooks/useApiPurchase';
import {
	AsyncContainer,
	type TAsyncContainerComponentProps,
	useAsyncContainerRef,
} from 'js/components/molecules/Modal/AsyncContainer';
import { Beforeunload } from 'react-beforeunload';
import { purchaseConfig } from 'module/purchase/purchaseConfig';

type TAsyncAvastCheckoutContainerProps = {
	order: IEntityOrder;
};

export const useAsyncAvastCheckoutContainerRef = () => useAsyncContainerRef<TAsyncAvastCheckoutContainerProps>();

export const AsyncAvastCheckoutContainer = (
	props: TAsyncContainerComponentProps<TAsyncAvastCheckoutContainerProps>,
): ReactElement | null => {
	const { forwardedRef } = props;
	const { t } = useTranslation('components');
	const { loadingModalRef } = useAppContext();
	const isSuccess = useRef<boolean>(false);
	const cancelSubmission = useApiCancelSubmission();

	return (
		<AsyncContainer<TAsyncAvastCheckoutContainerProps> ref={forwardedRef}>
			{({ order }) => (
				<Modal
					show
					backdrop="static"
					testId="checkout"
					size="lg"
					onHide={async () => {
						if (isSuccess.current) {
							forwardedRef.current?.onSuccess(STATUS_SUCCESS);
						} else {
							logDebug(`Revert order #${order.id} to "Save in progress" / "Quote"`);
							loadingModalRef.current?.show({
								title: t(purchaseConfig.trPrefix('common.savingOrder')),
								disableBeforeUnload: true,
							});
							await cancelSubmission.mutateAsync({ params: { standardOrderId: order.id }, data: {} });
							loadingModalRef.current?.hide();
							forwardedRef.current?.onCancel();
						}
					}}
				>
					<Modal.Header>
						{t('checkout.shoppingCart.title')} <small>#{order.id}</small>
					</Modal.Header>
					<Modal.Body>
						<AvastCheckout
							order={order}
							onSuccess={() => {
								isSuccess.current = true;
							}}
						/>
					</Modal.Body>
					<Beforeunload onBeforeunload={() => t('error:beforeUnload')} />
				</Modal>
			)}
		</AsyncContainer>
	);
};
