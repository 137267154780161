import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router';
import { AppLogo } from 'js/components/common';

type THeaderLogoProps = {
	collapsedLogo?: boolean;
};

export const HeaderLogo = (props: THeaderLogoProps): ReactElement => {
	const { t } = useTranslation();

	return (
		<div className="logo-container">
			<Link to={RouteName.HOMEPAGE.DEFAULT} className="d-block text-white">
				<AppLogo collapsedLogo={props.collapsedLogo} title={t('app:appName')} className="logo" />
			</Link>
		</div>
	);
};
