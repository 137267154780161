import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { AppLogo, EnvLabel } from 'js/components/common';
import type { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

type TSecurityFormLayoutProps = {
	title: string;
	columns?: 1 | 2;
};

export const SecurityFormLayout = (props: PropsWithChildren<TSecurityFormLayoutProps>) => {
	const { title, children, columns = 1 } = props;
	const { t } = useTranslation();

	return (
		<div className="section__security-login-form">
			<DynamicPageTitleItem text={title} />
			<EnvLabel className="env-label" />

			<div className="content-container">
				<div className="logo-container">
					<AppLogo title={t('app:appName')} className="logo" />
				</div>
				<div className="my-3 my-md-6">{children}</div>
				<div className="text-center fs-sm text-muted">
					<Trans i18nKey="app:copyright" values={{ year: DateTime.now().year }} />
				</div>
			</div>
		</div>
	);
};
