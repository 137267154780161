import type { ReactElement } from 'react';
import classNames from 'classnames';
import { CONFIG } from 'config';
import {
	type TAvastCheckoutEventData,
	useAvastCheckoutEventHandler,
} from 'module/purchase/hooks/useAvastCheckoutEventHandler';
import { useEventListener } from 'js/hooks/useEventListener';
import { buildParametrizedRoute } from 'js/utils/common';
import type { IEntityOrder } from 'module/orders';

type TAvastCheckoutProps = {
	onSuccess: () => void;
	order: IEntityOrder;
};

export const AvastCheckout = (props: TAvastCheckoutProps): ReactElement | null => {
	const eventHandler = useAvastCheckoutEventHandler(props);
	useEventListener<MessageEvent<TAvastCheckoutEventData>>('message', eventHandler);
	const isTest = props.order.flagTest ? 1 : null; // Set to url when is 1 only

	const url = buildParametrizedRoute(`${CONFIG.APP.AVAST_CHECKOUT_URL}/:locale/:theme`, {
		locale: props.order.checkoutLocale,
		theme: 'web',
		testOrder: isTest,
		developer: isTest,
	});

	return (
		<iframe
			src={url}
			name="AVAST_CHECKOUT"
			title="Avast Checkout"
			className={classNames('component__checkout-iframe')}
		/>
	);
};
