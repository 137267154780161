import React, { type PropsWithChildren, type ReactElement, useState } from 'react';
import { RouteName } from 'module/RouteName';
import type { IRegistrationData } from 'module/registration';
import { getRegistrationData } from 'module/registration/utils/common';
import { Navigate } from 'react-router';
import { RegistrationStepEnum } from 'module/registration/enums';
import { useAuthContext } from 'js/contexts';
import invariant from 'invariant';
import { useApiPartnerTermsList } from 'module/partners/hooks/useApiPartners';
import { LoadingFullScreenPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useFeatureFlag } from 'js/hooks/useFeatureFlag';
import { FeatureFlagEnum } from 'config/featureFlags';

export interface IRegistrationContext {
	partnerId: number;
	partner: IRegistrationData;
	step: RegistrationStepEnum;
	setStep: (step: RegistrationStepEnum) => void;
	isTaxExemptEligible?: boolean;
	hasTerms?: boolean;
}

/**
 * Create context
 */
const RegistrationContext = React.createContext<IRegistrationContext | null>(null);
RegistrationContext.displayName = 'RegistrationContext';

export const useRegistrationContext = () => {
	const context = React.useContext(RegistrationContext);

	invariant(
		context !== null,
		'Registration context is undefined, please verify you are calling useRegistrationContext() as child of a <RegistrationContextProvider> component.',
	);

	return context;
};

export const RegistrationContextProvider = ({ children }: PropsWithChildren<object>): ReactElement => {
	const { authPartner } = useAuthContext();
	const [step, setStep] = useState<RegistrationStepEnum>(RegistrationStepEnum.INIT);
	const { data: partnerTermsList, query: queryTerms } = useApiPartnerTermsList({
		filter: { partnerId: authPartner?.id },
		queryConfig: {
			enabled: Boolean(authPartner?.id),
		},
	});
	const taxExemptionSectionFlag = useFeatureFlag(FeatureFlagEnum.TAX_EXEMPTION_SECTION);

	if (!authPartner?.id) {
		return <Navigate to={RouteName.SECURITY.LOGOUT} replace />;
	}

	if (!authPartner.canRegister || authPartner.isRegistered) {
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} replace />;
	}

	if (queryTerms.isLoading) {
		return <LoadingFullScreenPlaceholder />;
	}

	if (queryTerms.isError) {
		return <Navigate to={RouteName.SECURITY.LOGOUT} replace />;
	}

	const value: IRegistrationContext = {
		partnerId: authPartner.id,
		partner: getRegistrationData(authPartner),
		isTaxExemptEligible: authPartner.isTaxExemptEligible && taxExemptionSectionFlag.isEnabled,
		hasTerms: partnerTermsList.length !== 0,
		step,
		setStep,
	};

	return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};
