import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { submitOrderApiError } from 'module/purchase/utils/apiError';
import {
	AsyncContainer,
	type TAsyncContainerComponentProps,
	useAsyncContainerRef,
} from 'js/components/molecules/Modal/AsyncContainer';
import { ConfirmationModal } from 'module/purchase/components/submitOrder/ConfirmationModal';
import { usePlaceOrder } from 'module/purchase/hooks/usePlaceOrder';
import {
	AsyncSuccessOrderModal,
	useAsyncSuccessOrderModalRef,
} from 'module/purchase/components/submitOrder/AsyncSuccessOrderModal';
import { HgopContainer, type THgopContainer } from 'module/purchase/components/submitOrder/HgopContainer';
import type { IEntityOrder } from 'module/orders';
import type { TPaymentTypeAction } from 'module/purchase';
import { useQueryClient } from '@tanstack/react-query';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useNavigate } from 'react-router';
import { isEndCustomerQuote } from 'module/orders/utils/common';
import { PaymentTypeActionEnum } from 'module/purchase/enums';
import { UserOrderActionEnum } from 'module/orders/enums';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useApiErrorContext, useAppContext, useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import {
	AsyncAvastCheckoutContainer,
	useAsyncAvastCheckoutContainerRef,
} from 'module/purchase/components/submitOrder/AsyncAvastCheckoutContainer';

export type TAsyncSubmitOrderProps = {
	action: TPaymentTypeAction;
};

export const AsyncSubmitOrder = (props: TAsyncContainerComponentProps<TAsyncSubmitOrderProps>): ReactElement | null => {
	const { forwardedRef } = props;

	const { t } = useTranslation(purchaseConfig.trNamespace);
	const navigate = useNavigate();
	const { loadingModalRef } = useAppContext();
	const {
		orderState: { action },
	} = useOrderContext();
	const successRef = useAsyncSuccessOrderModalRef();
	const hgopRef = useAsyncContainerRef<THgopContainer>();
	const avastCheckoutRef = useAsyncAvastCheckoutContainerRef();
	const { setError } = useApiErrorContext();
	const placeOrder = usePlaceOrder();
	const queryClient = useQueryClient();

	// When user cancel placing an order, or data are incomplete
	const onCancel = useCallback(() => {
		loadingModalRef.current?.hide();
		forwardedRef.current?.onCancel();
	}, [forwardedRef, loadingModalRef]);

	// On success - payment is done
	const onSuccess = useCallback(
		(order: IEntityOrder, forceAction?: TPaymentTypeAction) => {
			successRef.current?.show({ order, action: forceAction || action! }).then(async (link) => {
				queryClient.removeQueries();

				// Navigate
				const navigateObject = navigateLinkToNavigateObject(link || ordersConfig.detailLink(order));
				await navigate(navigateObject.to, navigateObject.options);

				forwardedRef.current?.onSuccess();
			});
		},
		[action, forwardedRef, successRef, queryClient, navigate],
	);

	// When user confirm placing an order
	const onConfirm = useCallback(() => {
		loadingModalRef.current?.show({
			title: t('common.savingOrder'),
			disableBeforeUnload: true,
		});

		placeOrder(
			(order) => {
				loadingModalRef.current?.hide();
				if (
					isDefined(order.chargeOrderId) &&
					order.userOrderAction === UserOrderActionEnum.HGOP &&
					!isEndCustomerQuote(order)
				) {
					hgopRef.current?.show({ order }).then((result) => {
						if (result) {
							onSuccess(order);
						} else {
							onSuccess(order, order.quote?.flag ? PaymentTypeActionEnum.QUOTE : PaymentTypeActionEnum.SAVE);
						}
					});
				} else if (order.userOrderAction === UserOrderActionEnum.AVAST_CHECKOUT && !isEndCustomerQuote(order)) {
					avastCheckoutRef.current?.show({ order }).then((result) => {
						if (result) {
							onSuccess(order);
						} else {
							onSuccess(order, order.quote?.flag ? PaymentTypeActionEnum.QUOTE : PaymentTypeActionEnum.SAVE);
						}
					});
				} else {
					onSuccess(order);
				}
			},
			(error) => {
				loadingModalRef.current?.hide();
				if (error) {
					setError({
						error,
						onClose() {
							onCancel();
						},
						resolve: submitOrderApiError,
					});
				} else {
					onCancel();
				}
			},
		);
	}, [loadingModalRef, placeOrder, setError, t, onCancel, onSuccess, hgopRef, avastCheckoutRef]);

	return (
		<AsyncContainer<TAsyncSubmitOrderProps> ref={forwardedRef}>
			{({ action }) => (
				<>
					<ConfirmationModal
						show={forwardedRef.current?.isOpen() || false}
						onSubmit={onConfirm}
						onCancel={onCancel}
						action={action}
					/>
					<AsyncContainer ref={hgopRef}>
						{({ order }) => <HgopContainer id={order.id} chargeOrderId={order.chargeOrderId} parentRef={hgopRef} />}
					</AsyncContainer>
					<AsyncAvastCheckoutContainer forwardedRef={avastCheckoutRef} />
					<AsyncSuccessOrderModal forwardedRef={successRef} />
				</>
			)}
		</AsyncContainer>
	);
};
