import { BillingSystemPaymentTypeEnum, ProductBrandEnum } from 'module/purchase/enums';
import type { IPaymentTypeError } from 'module/purchase';
import { useOrderContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { useCallback } from 'react';

export const usePaymentTypeError = (): ((paymentType: BillingSystemPaymentTypeEnum) => IPaymentTypeError | null) => {
	const { orderState } = useOrderContext();
	const { t } = useTranslation(purchaseConfig.trNamespace);

	return useCallback(
		(paymentType) => {
			if (paymentType === BillingSystemPaymentTypeEnum.AVAST_CART_CHECKOUT) {
				const hasAvg = orderState.items.some((item) => item.product.group.brand === ProductBrandEnum.AVG);
				const hasNonAvg = orderState.items.some((item) => item.product.group.brand !== ProductBrandEnum.AVG);
				if (hasAvg && hasNonAvg) {
					return {
						text: t('error.paymentType.mixBrandsWithAvgProducts.text'),
						detail: t('error.paymentType.mixBrandsWithAvgProducts.detail'),
					};
				}
			}

			return null;
		},
		[orderState, t],
	);
};
