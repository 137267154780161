import { useCallback } from 'react';
import { logDebug } from 'js/utils/app';
import { CONFIG } from 'config';

export type TAvastCheckoutEventData = {
	checkoutOrderId: string;
};

type TAvastCheckoutEventHandlerProps = {
	onSuccess: () => void;
};

export const useAvastCheckoutEventHandler = (props: TAvastCheckoutEventHandlerProps) => {
	const { onSuccess } = props;

	return useCallback(
		(event: MessageEvent<TAvastCheckoutEventData>) => {
			// Accept messages only from Avast Checkout domain
			if (event.origin !== CONFIG.APP.AVAST_CHECKOUT_URL) {
				return;
			}

			logDebug('Avast Checkout MessageEvent', event.data);

			// Order completed
			if (event.data?.checkoutOrderId) {
				onSuccess();
			}
		},
		[onSuccess],
	);
};
