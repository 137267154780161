import type { IAuthContext } from 'js/contexts/AuthContext';

export enum FeatureFlagEnum {
	CREDIT_CARD = 0,
	TAX_EXEMPTION_FORM = 1,
	TAX_EXEMPTION_SECTION = 2,
}

interface IFeatureFlagContext {
	authContext: IAuthContext;
}

interface IFeatureFlag {
	isEnabled(context: IFeatureFlagContext): boolean;
}

const config: Record<FeatureFlagEnum, IFeatureFlag> = {
	[FeatureFlagEnum.CREDIT_CARD]: {
		isEnabled(): boolean {
			return false;
		},
	},
	// Tax Exempt form to submit tax exemption certificate
	[FeatureFlagEnum.TAX_EXEMPTION_FORM]: {
		isEnabled(): boolean {
			return false;
		},
	},
	// Tax Exempt section in registration form and user account
	[FeatureFlagEnum.TAX_EXEMPTION_SECTION]: {
		isEnabled(context): boolean {
			const isCreditEligible = Boolean(context.authContext.authCompany?.isCreditEligible);
			const isTaxExemptionFormFlagEnabled = getFeatureFlag(FeatureFlagEnum.TAX_EXEMPTION_FORM).isEnabled(context);
			return isTaxExemptionFormFlagEnabled || isCreditEligible;
		},
	},
};

export const getFeatureFlag = (flag: FeatureFlagEnum) => {
	return config[flag];
};
