import type { PropsWithChildren } from 'react';
import { CONFIG } from 'config';
import { ThemeEnum } from 'js/enums';
import { EmpowerTheme } from 'js/theme/EmpowerTheme';
import { AvastTheme } from 'js/theme/AvastTheme';

export const AppTheme = (props: PropsWithChildren) => {
	switch (CONFIG.THEME) {
		case ThemeEnum.EMPOWER:
			return <EmpowerTheme {...props} />;
		default:
			return <AvastTheme {...props} />;
	}
};
