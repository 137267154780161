import type { IEntityClosingBalance } from 'module/closingBalance';
import { isGcOrder } from 'module/orders/utils/common';
import { ClosingBalanceOrderStatusEnum } from 'module/closingBalance/enums';

export const hasAvailableDocuments = (closingBalance: IEntityClosingBalance): boolean => {
	if (closingBalance.billing?.status === ClosingBalanceOrderStatusEnum.COMPLETE) {
		return true;
	}

	if (!isGcOrder(closingBalance)) {
		if (closingBalance.billing?.status === ClosingBalanceOrderStatusEnum.WAITING_PAYMENT) {
			return true;
		}
	}

	return false;
};
